import React, { useState, useEffect } from 'react'
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    message,
    Divider,
    Checkbox,
} from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../request'

const { Option } = Select

const EditInscriptionForm = () => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams() // Retrieve the ID from the URL
    const [isMinor, setIsMinor] = useState(false) // State for showing parent fields (set from backend data)

    // Fetch user data and prefill the form
    useEffect(() => {
        const fetchInscriptionData = async () => {
            try {
                const { data } = await api.get(`/inscriptions/${id}`)
                form.setFieldsValue({
                    ...data,
                    dateDeNaissance: data.dateDeNaissance
                        ? data.dateDeNaissance.split('T')[0]
                        : '', // Prefill birth date as a string in YYYY-MM-DD format
                })
                setIsMinor(data.mineur) // Set isMinor based on backend data
            } catch (error) {
                message.error('Erreur lors du chargement des données.')
            }
        }

        fetchInscriptionData()
    }, [id, form])

    // Handle form submission
    const onFinish = async (values) => {
        setLoading(true)
        try {
            await api.patch(`/inscriptions/${id}`, values) // Use the ID from URL params
            message.success('Données mises à jour avec succès!')
            navigate('/dashboard') // Redirect to dashboard
        } catch (error) {
            message.error("Erreur lors de la mise à jour de l'inscription.")
        } finally {
            setLoading(false)
        }
    }

    // Handle cancel button click
    const onCancel = () => {
        navigate('/dashboard') // Redirect without saving
    }

    return (
        <div style={{ maxWidth: 600, margin: '0 auto', padding: '20px' }}>
            <h2>Modifier les informations d'inscription</h2>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/* General Information */}
                <Divider>Informations Générales</Divider>
                <Form.Item
                    label="Nom"
                    name="nom"
                    rules={[{ required: true, message: 'Le nom est requis' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Prénom"
                    name="prenom"
                    rules={[
                        { required: true, message: 'Le prénom est requis' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Date de Naissance"
                    name="dateDeNaissance"
                    rules={[
                        {
                            required: true,
                            message: 'La date de naissance est requise',
                        },
                    ]}
                >
                    <Input type="date" />
                </Form.Item>
                <Form.Item label="Nationalité" name="nationality">
                    <Input />
                </Form.Item>
                <Form.Item label="Téléphone Portable" name="portable">
                    <Input />
                </Form.Item>
                <Form.Item label="Adresse Email" name="mail">
                    <Input />
                </Form.Item>

                {/* Emergency Contact Section */}
                <Divider>Contact d'Urgence</Divider>
                <Form.Item
                    label="Nom du Contact d'Urgence"
                    name={['emergencyContact', 'nom']}
                    rules={[{ required: true, message: 'Le nom est requis' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Prénom du Contact d'Urgence"
                    name={['emergencyContact', 'prenom']}
                    rules={[
                        { required: true, message: 'Le prénom est requis' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Téléphone du Contact d'Urgence"
                    name={['emergencyContact', 'telephone']}
                    rules={[
                        { required: true, message: 'Le téléphone est requis' },
                    ]}
                >
                    <Input />
                </Form.Item>

                {/* License Type */}
                <Divider>Type de Licence</Divider>
                <Form.Item
                    label="Type de Licence"
                    name="licenseType"
                    rules={[
                        {
                            required: true,
                            message: 'Le type de licence est requis',
                        },
                    ]}
                >
                    <Select placeholder="Sélectionnez le type de licence">
                        <Option value="A">A</Option>
                        <Option value="B">B</Option>
                        {/*<Option value="C">C (Cours du mercredi)</Option>*/}
                    </Select>
                </Form.Item>

                {/* Parent Information and Autorisation (Only if minor) */}
                {isMinor && (
                    <>
                        <Divider>Informations des Parents</Divider>
                        <Form.Item
                            label="Nom du Père"
                            name={['parentInfo', 'pere', 'nom']}
                            rules={[
                                {
                                    message: 'Le nom du père est requis',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Prénom du Père"
                            name={['parentInfo', 'pere', 'prenom']}
                            rules={[
                                {
                                    message: 'Le prénom du père est requis',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Téléphone du Père"
                            name={['parentInfo', 'pere', 'telephone']}
                            rules={[
                                {
                                    message: 'Le téléphone du père est requis',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Nom de la Mère"
                            name={['parentInfo', 'mere', 'nom']}
                            rules={[
                                {
                                    message: 'Le nom de la mère est requis',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Prénom de la Mère"
                            name={['parentInfo', 'mere', 'prenom']}
                            rules={[
                                {
                                    message: 'Le prénom de la mère est requis',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Téléphone de la Mère"
                            name={['parentInfo', 'mere', 'telephone']}
                            rules={[
                                {
                                    message:
                                        'Le téléphone de la mère est requis',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Autorisation de Départ Seul"
                            name="autorisationDepartSeul"
                            valuePropName="checked"
                        >
                            <Checkbox>Autorisation de départ seul</Checkbox>
                        </Form.Item>
                    </>
                )}

                {/* Consent Section */}
                <Divider>Consentement</Divider>
                <Form.Item
                    label="Cession du Droit à l'Image"
                    name="photoAuthorization"
                >
                    <Radio.Group>
                        <Radio value={true}>
                            Je donne mon accord pour apparaître sur les réseaux
                            sociaux
                        </Radio>
                        <Radio value={false}>
                            Non à la cession du droit à l'image
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                {/* Buttons */}
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{ marginRight: '10px' }}
                    >
                        Enregistrer
                    </Button>
                    <Button type="default" onClick={onCancel}>
                        Annuler
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default EditInscriptionForm
