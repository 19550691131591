import React from 'react'
import useLocalStorage from './hooks/useLocalStorage'
import AppRoutes from './components/AppRoutes'

const App = () => {
    const [token, setToken] = useLocalStorage('club-token', '')

    return (
        <>
            <AppRoutes />
        </>
    )
}

export default App
