import React, { useEffect, useState } from 'react'
import api from '../request'
import Loader from '../components/Loader'
import { QRCodeSVG } from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { message } from 'antd'
import logo from '../assets/logo.png'
import ErrorMessage from '../components/ErrorMessage'

const GenerateInscriptionLinkPage = () => {
    const [link, setLink] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        const fetchFunction = async () => {
            try {
                const { data } = await api.post('/inscriptions/share')

                setLink(data.url)
                setIsLoading(false)
            } catch (error) {
                console.error(error)
                setIsLoading(false)
                setError('An error occurred while fetching the link')
            }
        }

        fetchFunction()
    }, [])

    const handleCopy = () => {
        setCopied(true)
        message.success('Link copied to clipboard!')
    }

    if (isLoading) {
        return <Loader />
    }

    if (error) {
        return <ErrorMessage message={`Error: ${error}`} />
    }

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    cursor: 'pointer',
                }}
            >
                <div>Scannez-moi pour accéder au formulaire d'inscription</div>
                <CopyToClipboard text={link} onCopy={handleCopy}>
                    <QRCodeSVG
                        value={link}
                        size={300}
                        level="H"
                        marginSize={0}
                        imageSettings={{
                            src: logo, // Replace with your image path
                            x: null, // Let the library center it automatically
                            y: null,
                            height: 30,
                            width: 30,
                            excavate: true, // Ensures the image won't overlap the QR pattern
                        }}
                    />
                </CopyToClipboard>

                {copied && <p style={{ color: 'green' }}>Lien copié!</p>}
            </div>
        </div>
    )
}

export default GenerateInscriptionLinkPage
