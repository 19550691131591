import React from 'react'
import { Typography, Collapse, Button } from 'antd'

const { Title, Paragraph, Text } = Typography
const { Panel } = Collapse

function PrivacyPolicy() {
    return (
        <div style={{ maxWidth: '800px', margin: 'auto', padding: '20px' }}>
            <Title level={2}>Politique de confidentialité</Title>

            <Paragraph>
                Nous respectons votre vie privée et nous engageons à protéger
                les données personnelles que vous nous fournissez. Cette
                politique explique comment nous collectons, utilisons et
                protégeons vos données.
            </Paragraph>

            <Collapse bordered={false} accordion>
                <Panel header="Données collectées" key="1">
                    <Paragraph>
                        Nous collectons les informations suivantes :
                        <ul>
                            <li>Nom, prénom</li>
                            <li>Date de naissance</li>
                            <li>Nationalité</li>
                            <li>Adresse e-mail et numéro de téléphone</li>
                            <li>Contact d'urgence</li>
                            <li>Signature numérique</li>
                            <li>Informations des parents pour les mineurs</li>
                            <li>Identifiant FFE</li>
                            <li>Mot de passe FFE (crypté)</li>
                        </ul>
                        Ces données sont nécessaires pour assurer votre
                        inscription, la bonne gestion de votre adhésion, et la
                        gestion de la mailing list.
                    </Paragraph>
                </Panel>

                <Panel header="Finalité du traitement des données" key="2">
                    <Paragraph>
                        Les données collectées sont utilisées pour :
                        <ul>
                            <li>Assurer la gestion des inscriptions</li>
                            <li>Suivre les paiements</li>
                            <li>
                                Gérer les contacts d'urgence en cas de besoin
                            </li>
                            <li>
                                Respecter les obligations envers la Fédération
                                Française des Échecs (FFE)
                            </li>
                            <li>
                                Maintenir notre mailing list pour des
                                communications importantes
                            </li>
                        </ul>
                        Nous utilisons l'adresse e-mail{' '}
                        <Text code>oualiyannick@gmail.com</Text> pour
                        communiquer avec les membres.
                    </Paragraph>
                </Panel>

                <Panel header="Base légale du traitement" key="3">
                    <Paragraph>
                        Nous traitons vos données sur la base de :
                        <ul>
                            <li>
                                <Text strong>Intérêt légitime</Text> : Assurer
                                la gestion associative, garantir votre sécurité
                                pendant les événements et maintenir la mailing
                                list.
                            </li>
                            <li>
                                <Text strong>Obligations légales</Text> :
                                Respect des règlements imposés par la FFE.
                            </li>
                            <li>
                                <Text strong>Consentement</Text> : Pour les
                                informations des parents concernant les mineurs,
                                et pour l'utilisation de vos données à des fins
                                non essentielles.
                            </li>
                        </ul>
                    </Paragraph>
                </Panel>

                <Panel header="Hébergement et sécurité des données" key="4">
                    <Paragraph>
                        Vos données sont hébergées sur un serveur privé situé à
                        mon domicile, afin d'assurer leur sécurité et leur
                        accessibilité. Le serveur est un Raspberry Pi, et nous
                        veillons à ce qu'il soit correctement protégé contre
                        tout accès non autorisé.
                    </Paragraph>
                    <Paragraph>
                        Nous ne sommes pas obligés de fournir l'adresse exacte,
                        mais nous garantissons que les mesures de sécurité
                        adéquates sont en place pour protéger vos données.
                    </Paragraph>
                    <Paragraph>
                        Votre mot de passe FFE est stocké de manière sécurisée
                        et cryptée, et n'est pas accessible par les
                        administrateurs. Cela garantit que vos informations de
                        connexion restent confidentielles et cela nous permet de
                        vous connecter automatiquement à la plateforme FFE pour
                        gérer vos inscriptions.
                    </Paragraph>
                </Panel>

                <Panel header="Conservation des données" key="5">
                    <Paragraph>
                        Vos données sont conservées tant que vous êtes membre de
                        l'association, puis supprimées dans un délai raisonnable
                        après la fin de votre adhésion. La mailing list sera
                        maintenue tant que vous ne vous désabonnez pas ou n'en
                        faites pas la demande.
                    </Paragraph>
                </Panel>

                <Panel header="Vos droits" key="6">
                    <Paragraph>
                        Conformément au RGPD, vous avez le droit de :
                        <ul>
                            <li>Accéder à vos données</li>
                            <li>Demander leur rectification ou suppression</li>
                            <li>
                                Vous opposer à leur traitement dans certains cas
                            </li>
                        </ul>
                        Pour exercer vos droits, vous pouvez nous contacter à
                        l'adresse suivante :{' '}
                        <Text code>oualiyannick@gmail.com</Text>.
                    </Paragraph>
                </Panel>
            </Collapse>
        </div>
    )
}

export default PrivacyPolicy
