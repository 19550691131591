import React, { useRef, useState } from 'react'
import { Form, Input, Button, Checkbox, Radio, Col, Row, message } from 'antd'
import Center from '../components/Center'
import SignatureCanvas from 'react-signature-canvas'
import api from '../request'
import { useSearchParams } from 'react-router-dom'
import { getErrorMessage } from '../helpers/errorHelpers'
import { calculateAge } from '../helpers/dateHelpers'

// Create a function to calculate the age with day month and year as parameters. This param will be an object with keys day, month and year

function InscriptionPage() {
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')

    const apiInscriptionRoute = token ? 'sharedInscription' : 'inscriptions'

    const [submitted, setSubmitted] = useState(false) // New state for submission status
    const [signature, setSignature] = useState('')
    const [date, setDate] = useState('')

    const canvasRef = useRef(null)

    const age = calculateAge(date) // Calculate age directly
    const isMinor = age < 18 // Determine if the person is a minor

    const onFinish = async (values) => {
        // Create a new FormData instance
        const formData = new FormData()

        const data = JSON.stringify(values)
        // Append all form values to the FormData instance
        formData.append('info', data)
        // Append the signature blob to the FormData
        formData.append('signature', signature)
        // to iso string
        formData.append('dateDeNaissance', date)

        try {
            const response = await api.post(apiInscriptionRoute, formData, {
                ...(token && { params: { token } }), // Conditionally include params if token exists
                headers: { 'Content-Type': 'multipart/form-data' },
            })

            const httpStatus = response.status

            if (httpStatus === 201) {
                setSubmitted(true)
            } else {
                message.error("Erreur lors de l'envoi du formulaire.")
            }

            message.success('Votre inscription est envoyée, merci!')
        } catch (error) {
            message.error(
                `Erreur lors de l'envoi du formulaire: ${getErrorMessage(
                    error
                )}`
            )
        }
    }

    if (submitted) {
        return (
            <div style={{ textAlign: 'center' }}>
                <h2>Votre inscription est envoyée, merci!</h2>
                {!token && (
                    <Button onClick={() => setSubmitted(false)}>
                        Inscrire un nouveau joueur
                    </Button>
                )}
            </div>
        )
    }

    return (
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
            <div>
                <>
                    <h1>Formulaire d'inscription</h1>
                    <Form
                        onFinish={onFinish}
                        labelAlign="right" // Align labels at the top
                        labelCol={{ flex: '0 0 150px' }} // Set label width
                    >
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="nom"
                                    label="Nom"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Veuillez entrer votre nom.',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="prenom"
                                    label="Prénom"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Veuillez entrer votre prénom.',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Date de Naissance"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Veuillez entrer votre date de naissance.',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) =>
                                            setDate(e.target.value)
                                        }
                                        type="date"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="nationality"
                                    label="Nationalité"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Veuillez entrer votre nationalité.',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}></Row>

                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item name="portable" label="Portable">
                                    <Input type="phone" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="mail"
                                    label="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Veuillez entrer votre adresse mail.',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Center>
                            <h3>Cession de droit a l'image</h3>
                        </Center>
                        <Center>
                            <Form.Item
                                name="photoAuthorization"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez faire un choix.',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={true}>
                                        Je donne mon accord pour apparaitre sur
                                        les réseaux.
                                    </Radio>
                                    <Radio value={false}>Non</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Center>

                        {isMinor && (
                            <>
                                <h2>Informations des parents</h2>
                                <h3>Père</h3>
                                <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name={['parentInfo', 'pere', 'nom']}
                                            label="Nom du père"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name={[
                                                'parentInfo',
                                                'pere',
                                                'prenom',
                                            ]}
                                            label="Prénom du père"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name={[
                                                'parentInfo',
                                                'pere',
                                                'telephone',
                                            ]}
                                            label="Téléphone du père"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <h3>Mère</h3>
                                <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name={['parentInfo', 'mere', 'nom']}
                                            label="Nom de la mère"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name={[
                                                'parentInfo',
                                                'mere',
                                                'prenom',
                                            ]}
                                            label="Prénom de la mère"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name={[
                                                'parentInfo',
                                                'mere',
                                                'telephone',
                                            ]}
                                            label="Téléphone de la mère"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name="autorisationDepartSeul"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                J'autorise mon enfant à partir
                                                seul
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <div />
                                    </Col>
                                </Row>
                            </>
                        )}
                        <h2>
                            Informations de la personne à contacter en cas
                            d'urgence
                        </h2>
                        <Form.Item
                            name={['emergencyContact', 'nom']}
                            label="Nom d'urgence"
                            rules={[
                                {
                                    required: true,
                                    message: 'Veuillez entrer le nom.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['emergencyContact', 'prenom']}
                            label="Prénom d'urgence"
                            rules={[
                                {
                                    required: true,
                                    message: 'Veuillez entrer le prénom.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['emergencyContact', 'telephone']}
                            label="Téléphone d'urgence"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Veuillez entrer le numéro de telephonne.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <h2>Informations sur la licence</h2>
                        <Center>
                            <Form.Item
                                name="licenseType"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Veuillez selectionner votre licence.',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value="A">Licence A</Radio>
                                    <Radio value="B">Licence B</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Center>
                        <h2>Signature</h2>
                        <Center>
                            <div
                                style={{
                                    borderRadius: '12px', // Rounded corners for a smoother look
                                    border: '1px solid rgba(0, 0, 0, 0.1)', // Soft border
                                    display: 'inline-block',
                                    backgroundColor: 'white',
                                    boxShadow: `
      0 3px 6px rgba(0, 0, 0, 0.16), /* Subtle shadow for depth */
      0 10px 20px rgba(0, 0, 0, 0.12), /* Soft shadow spread */
      0 14px 30px rgba(0, 0, 0, 0.10), /* Larger, fainter shadow */
      0 19px 40px rgba(0, 0, 0, 0.08)  /* Final large shadow for elegance */
    `,
                                    padding: '10px', // Add padding for a more elegant look
                                }}
                            >
                                <SignatureCanvas
                                    penColor="black"
                                    canvasProps={{
                                        width: 300,
                                        height: 200,
                                        className: 'sigCanvas',
                                    }}
                                    ref={canvasRef}
                                    onEnd={() => {
                                        canvasRef.current
                                            .getTrimmedCanvas()
                                            .toBlob((blob) => {
                                                setSignature(blob)
                                            }, 'image/png')
                                    }}
                                />
                            </div>
                        </Center>

                        <Button
                            style={{ marginTop: 5 }}
                            onClick={() => {
                                canvasRef.current.clear()
                            }}
                        >
                            Effacer la signature
                        </Button>
                        <Form.Item
                            name="acceptPrivacyPolicy"
                            valuePropName="checked" // This ensures that the checkbox is tracked as "checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value
                                            ? Promise.resolve()
                                            : Promise.reject(
                                                  new Error(
                                                      'Veuillez accepter la politique de confidentialité.'
                                                  )
                                              ),
                                },
                            ]}
                        >
                            <Checkbox>
                                J'accepte la{' '}
                                <a
                                    href="/politique-confidentialite"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    politique de confidentialité
                                </a>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    marginTop: 20,
                                    width: '100%',
                                }}
                            >
                                Envoyer
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            </div>
        </div>
    )
}

export default InscriptionPage
