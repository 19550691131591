import _ from 'lodash'
import React, { useState } from 'react'
import m from 'moment'
import { Button, message } from 'antd'
import { saveAs } from 'file-saver'
import api from '../request'
import { DownloadOutlined } from '@ant-design/icons' // For saving the CSV file

const JsonToCsvExporter = ({ queryParams = {} }) => {
    const [loading, setLoading] = useState(false)

    // Function to convert JSON to CSV
    const jsonToCsv = (data) => {
        const csvRows = []

        // Get the headers from the keys of the first object
        const headers = Object.keys(data[0])
        csvRows.push(headers.join(','))

        // Loop through the data and convert each row to a CSV string
        data.forEach((row) => {
            const values = headers.map((header) => {
                const value = row[header]
                return `"${value}"` // Escape values with quotes
            })
            csvRows.push(values.join(','))
        })

        return csvRows.join('\n')
    }

    // Function to fetch data and export it to CSV
    const exportToCsv = async () => {
        setLoading(true)
        try {
            // Build the query parameters based on the props

            // Fetch data from the API
            const { data: response } = await api.get('/inscriptions', {
                params: queryParams,
            })

            const { data } = response

            if (data.length === 0) {
                message.warning('No data available for export')
                setLoading(false)
                return
            }

            const jsonData = _.map(data, (item) => ({
                id: item._id,
                nom: item.nom,
                prenom: item.prenom,
                elo: item.elo,
                mail: item.mail,
                portable: `'${item.portable}`, // Prefix with a single quote to force text format
                dateDeNaissance: m(item.dateDeNaissance).format('DD-MM-YYYY'),
                mineur: item.mineur ? 'Oui' : 'Non',
                licenseType:
                    item.licenseType === 'C' ? 'Cours (B)' : item.licenseType,
                dateInscription: m(item.dateInscription).format('DD-MM-YYYY'),
            }))

            // Convert JSON data to CSV
            const csvData = jsonToCsv(jsonData) // Assuming API returns { inscriptions: [...] }

            // Create a Blob and trigger download
            const blob = new Blob([csvData], {
                type: 'text/csv;charset=utf-8;',
            })
            saveAs(blob, `exported_data_${new Date().toISOString()}.csv`)

            message.success('Exported to CSV successfully!')
        } catch (error) {
            message.error('Error while exporting data')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={exportToCsv}
            loading={loading}
        />
    )
}

export default JsonToCsvExporter
