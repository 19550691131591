import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useLocalStorage from '../hooks/useLocalStorage'
import { Form, Input, Button, Typography, Card, message } from 'antd'
import api from '../request'
import Loader from '../components/Loader'

const { Title, Text } = Typography

const AdminConnect = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const [token, setToken] = useLocalStorage('club-token', '')
    // Redirect if already logged in
    useEffect(() => {
        if (token) {
            navigate('/')
        }
    }, [token, navigate])

    const handleLogin = async () => {
        try {
            setIsLoading(true)
            const response = await api.post('/login', {
                username,
                password,
            })

            if (response.status === 200) {
                setToken(response.data.token)
                window.location.reload()
            }
        } catch (error) {
            setIsLoading(false)
            const errorMsg = `Nom d'utilisateur ou mot de passe incorrect`
            setError(errorMsg)
            message.error(errorMsg)
        }
    }

    const onFinish = () => {
        handleLogin()
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // backgroundColor: '#f0f2f5',
            }}
        >
            {isLoading && <Loader />}
            <Card
                style={{
                    width: 400,
                    padding: '30px 20px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Title
                    level={2}
                    style={{ textAlign: 'center', marginBottom: 20 }}
                >
                    Connexion FFE
                </Title>
                <Form
                    name="admin_login"
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {error && (
                        <Text type="danger" style={{ marginBottom: 10 }}>
                            {error}
                        </Text>
                    )}
                    <Form.Item
                        label="Numero club FFE"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Veuillez entrer votre nom d'utilisateur",
                            },
                        ]}
                    >
                        <Input
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Entrez votre nom d'utilisateur"
                            onPressEnter={handleLogin} // Handle 'Enter' key press
                        />
                    </Form.Item>
                    <Form.Item
                        label="Mot de passe"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez entrer votre mot de passe',
                            },
                        ]}
                    >
                        <Input.Password
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Entrez le mot de passe"
                            onPressEnter={handleLogin} // Handle 'Enter' key press
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Connexion
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

export default AdminConnect
