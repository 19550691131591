import React from 'react'
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useNavigate,
} from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import HomePage from '../pages/HomePage'
import PolitiqueConfidentialite from '../pages/PolitiqueConfidentialite'
import InscriptionPage from '../pages/InscriptionPage'
import GenerateInscriptionLinkPage from '../pages/GenerateInscriptionLinkPage'
import ShareInscription from '../pages/ShareInscription'
import AdminConnect from '../pages/AdminConnect'
import AdminDashboard from '../pages/AdminDashboard'
import EditInscription from '../pages/EditInscription'
import logo from '../assets/logo.png'
import useLocalStorage from '../hooks/useLocalStorage'

const Header = () => {
    const [token, setToken] = useLocalStorage('club-token', '') // Récupérer le token depuis le localStorage
    const navigate = useNavigate()

    return (
        <div style={{ textAlign: 'center' }}>
            <img
                src={logo}
                alt="Description"
                style={{
                    width: '150px',
                    margin: '20px 0',
                    ...(token && { cursor: 'pointer' }),
                    userSelect: 'none',
                }}
                onClick={() => token && navigate('/')} // Use React Router navigation
            />
            {token && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: '10px',
                        cursor: 'pointer',
                        userSelect: 'none',
                        color: 'red',
                    }}
                    onClick={() => {
                        setToken('')
                        window.location.reload()
                    }}
                >
                    Déconnexion
                </div>
            )}
        </div>
    )
}

const AppRoutes = () => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <HomePage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="politique-confidentialite"
                    element={<PolitiqueConfidentialite />}
                />
                <Route
                    path="/inscription"
                    element={
                        <ProtectedRoute>
                            <InscriptionPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="generate-inscription-link"
                    element={
                        <ProtectedRoute>
                            <GenerateInscriptionLinkPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="shared-inscription"
                    element={<ShareInscription />}
                />
                <Route path="/connect" element={<AdminConnect />} />
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute>
                            <AdminDashboard />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/inscription/:id"
                    element={
                        <ProtectedRoute>
                            <EditInscription />
                        </ProtectedRoute>
                    }
                />
                {/* Catch-all route for non-existent routes */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    )
}

export default AppRoutes
