import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import useLocalStorage from '../hooks/useLocalStorage'
import api from '../request'
import Loader from './Loader'

const ProtectedRoute = ({ children }) => {
    const [token, setToken] = useLocalStorage('club-token', '') // Récupérer le token depuis le localStorage

    useEffect(() => {
        const fetchFunction = async () => {
            try {
                await api.get('/me')
            } catch (error) {
                console.error('Error fetching user data:', error)
                setToken('')
                window.location.reload()
            }
        }

        if (token) {
            fetchFunction()
        }
    }, [])

    // Si pas de token, rediriger vers la page de connexion
    if (!token) {
        return <Navigate to="/connect" />
    }

    // Sinon, autoriser l'accès à la page protégée
    return <>{children}</>
}

export default ProtectedRoute
