import React from 'react'
import { Card } from 'antd'

const CustomCard = ({ title, description, icon, onClick }) => {
    return (
        <Card
            hoverable
            onClick={onClick}
            style={{
                width: '100%',
                textAlign: 'center',
                borderRadius: '10px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease',
                height: '100%',
            }}
            bodyStyle={{ padding: '20px' }}
        >
            <div
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '3rem',
                    color: '#1890ff',
                    padding: '30px 0',
                }}
            >
                {icon}
            </div>
            <h2
                style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    margin: '0',
                    userSelect: 'none',
                }}
            >
                {title}
            </h2>
            <p style={{ fontSize: '1rem', color: '#777', userSelect: 'none' }}>
                {description}
            </p>
        </Card>
    )
}

export default CustomCard
