import React from 'react'
import { Spin } from 'antd'
import './Loader.css' // Import the CSS for styling

const FullScreenLoader = () => {
    return (
        <div className="fullscreen-loader">
            <div className="overlay" />
            <Spin size="large" className="white-spinner" />
        </div>
    )
}

export default FullScreenLoader
