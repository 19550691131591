import { useState, useEffect } from 'react'

function useLocalStorage(key, initialValue) {
    // Retrieve initial state from localStorage or use the default value
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key)
            return item ? item : initialValue
        } catch (error) {
            console.error('Error reading localStorage key:', key, error)
            return initialValue
        }
    })

    // Function to update the stored value in localStorage
    const setValue = (value) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value
            setStoredValue(valueToStore)

            // Only use JSON.stringify if the value is not a string
            if (typeof valueToStore === 'string') {
                window.localStorage.setItem(key, valueToStore)
            } else {
                window.localStorage.setItem(key, JSON.stringify(valueToStore))
            }
        } catch (error) {
            console.error('Error setting localStorage key:', key, error)
        }
    }

    // Function to remove the value from localStorage
    const removeValue = () => {
        try {
            window.localStorage.removeItem(key)
            setStoredValue(initialValue) // Reset to initial value
        } catch (error) {
            console.error('Error removing localStorage key:', key, error)
        }
    }

    // Sync changes with other windows (if the same localStorage changes elsewhere)
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === key) {
                setStoredValue(
                    event.newValue ? JSON.parse(event.newValue) : initialValue
                )
            }
        }

        window.addEventListener('storage', handleStorageChange)
        return () => {
            window.removeEventListener('storage', handleStorageChange)
        }
    }, [key, initialValue])

    return [storedValue, setValue, removeValue]
}

export default useLocalStorage
