import React, { useEffect, useState, useCallback } from 'react'
import {
    Table,
    Tag,
    Button,
    Modal,
    message,
    Input,
    Select,
    Tooltip,
    Dropdown,
    Menu,
    Switch,
    Slider,
} from 'antd'
import {
    InfoCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    MoreOutlined,
} from '@ant-design/icons'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import api from '../request'
import moment from 'moment'
import _ from 'lodash'
import FullInfoCard from '../components/FullInfoCard'
import Loader from '../components/Loader'
import ExportInscription from '../components/ExportInscription'
import { isMobile } from 'react-device-detect'

const { Option } = Select

const AdminDashboard = (callback, deps) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [inscriptions, setInscriptions] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentUser, setCurrentUser] = useState(null)

    const navigate = useNavigate()

    const fetchUserById = async (id) => {
        const { data } = await api.get(`/inscriptions/${id}`)

        setCurrentUser(data)
    }

    const handleImportMembers = async () => {
        setIsLoaded(false)
        const { data } = await api.post('/importMembersFromFfe')
        if (data) {
            message.success('Membres importés avec succès')
        } else {
            setIsLoaded(true)
            message.error("Erreur lors de l'import des membres")
        }

        await fetchInscriptions(
            pagination.current,
            pagination.pageSize,
            filters
        )
    }

    const [pagination, setPagination] = useState({
        current: searchParams.get('page')
            ? parseInt(searchParams.get('page'))
            : 1,
        pageSize: searchParams.get('pageSize')
            ? parseInt(searchParams.get('pageSize'))
            : 10,
        total: 0,
    })

    const [filters, setFilters] = useState({
        mineur:
            searchParams.get('mineur') === 'true'
                ? true
                : searchParams.get('mineur') === 'false'
                ? false
                : undefined,
        paid:
            searchParams.get('paid') === 'true'
                ? true
                : searchParams.get('paid') === 'false'
                ? false
                : undefined,
        search: '',
        licenseType: searchParams.get('licenseType') || 'Tous', // New state for licenseType
        sortBy: searchParams.get('sortBy') || 'dateInscription',
        sortOrder: searchParams.get('sortOrder') || 'desc',
        minElo: searchParams.get('minElo') || 1,
        maxElo: searchParams.get('maxElo') || 3000,
    })

    const [searchTerm, setSearchTerm] = useState(
        searchParams.get('search') || ''
    )

    const [sortOrder, setSortOrder] = useState(
        searchParams.get('sortOrder') || null
    )
    const [selectedUser, setSelectedUser] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

    const updateURLParams = (pagination, filters) => {
        const params = {}
        params.page = pagination.current
        params.pageSize = pagination.pageSize
        if (filters.mineur !== undefined) params.mineur = filters.mineur
        if (filters.paid !== undefined) params.paid = filters.paid
        if (filters.search) params.search = filters.search
        if (filters.warning) params.warning = filters.warning
        if (filters.licenseType && filters.licenseType !== 'Tous')
            params.licenseType = filters.licenseType // Only include in URL if not 'Tous'
        if (filters.sortOrder) params.sortOrder = filters.sortOrder
        if (filters.sortBy) params.sortBy = filters.sortBy
        if (filters.minElo) params.minElo = filters.minElo
        if (filters.maxElo) params.maxElo = filters.maxElo

        setSearchParams(params)
    }

    const handleCheckWarning = async (user) => {
        setIsLoaded(false)
        await api.patch('/inscriptions/syncFfe')
        await fetchInscriptions(
            pagination.current,
            pagination.pageSize,
            filters
        )
        setIsLoaded(true)
    }

    const handleChangeSortBy = (value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            sortBy: value,
        }))
    }

    const handleChangeSortOrder = (value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            sortOrder: value,
        }))
    }

    const queryParams = {
        page: pagination.current,
        limit: pagination.pageSize,
        ...(filters.mineur !== undefined && { mineur: filters.mineur }),
        ...(filters.paid !== undefined && { paid: filters.paid }),
        ...(filters.search && { search: filters.search }),
        ...(filters.licenseType &&
            filters.licenseType !== 'Tous' && {
                licenseType: filters.licenseType,
            }),
        ...(filters.warning && { warning: filters.warning }),
        ...(filters.sortOrder && { sortOrder: filters.sortOrder }),
        ...(filters.sortBy && { sortBy: filters.sortBy }),
        ...(filters.minElo && { minElo: filters.minElo }),
        ...(filters.maxElo && { maxElo: filters.maxElo }),
    }

    const fetchInscriptions = async (page, limit, filters) => {
        setIsLoaded(false)

        const { data } = await api.get('/inscriptions', { params: queryParams })

        setInscriptions(data.data)
        setPagination((prevPagination) => ({
            ...prevPagination,
            total: data.pagination.total,
        }))
        setIsLoaded(true)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination({
            ...pagination,
            current: pagination.current,
            pageSize: pagination.pageSize,
        })

        console.log(sorter.field, sorter.order)
        if (sorter && sorter.field) {
            handleChangeSortBy(sorter.field)
            handleChangeSortOrder(sorter.order === 'ascend' ? 'asc' : 'desc')
        } else {
            handleChangeSortBy(null)
            handleChangeSortOrder(null)
        }
    }

    const debouncedSearch = useCallback(
        _.debounce((value) => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                search: value,
            }))
            setPagination((prevPagination) => ({
                ...prevPagination,
                current: 1, // Reset to first page on search
            }))
        }, 500), // Increase debounce delay to 500ms
        []
    )

    useEffect(() => {
        fetchInscriptions(
            pagination.current,
            pagination.pageSize,
            filters,
            sortOrder
        )
        updateURLParams(pagination, filters)
    }, [pagination.current, pagination.pageSize, filters])

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
        debouncedSearch(e.target.value)
    }

    const debouncedHandleChangeEloRange = _.debounce((value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            minElo: value[0],
            maxElo: value[1],
        }))
        setPagination({
            ...pagination,
            current: 1, // Reset to first page on filter change
        })
    }, 500)

    const handleMineurFilterChange = (value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            mineur: value === 'Tous' ? undefined : value === 'Oui',
        }))
        setPagination({
            ...pagination,
            current: 1, // Reset to first page on filter change
        })
    }

    const handlePaidFilterChange = (value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            paid: value === 'Tous' ? undefined : value === 'Oui',
        }))
        setPagination({
            ...pagination,
            current: 1,
        })
    }

    const handleLicenseFilterChange = (value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            licenseType: value,
        }))
        setPagination({
            ...pagination,
            current: 1, // Reset to first page on filter change
        })
    }

    const handleWarningFilterChange = (value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            warning: value,
        }))
        setPagination({
            ...pagination,
            current: 1,
        })
    }

    const handleOpenModal = (inscription) => {
        setSelectedUser(inscription)
        setIsModalVisible(true)
        fetchUserById(inscription._id)
    }

    const handleOpenDeleteModal = (inscription) => {
        setIsDeleteModalVisible(true)
        setSelectedUser(inscription)
    }

    const handleCloseDeleteModal = () => {
        setIsDeleteModalVisible(false)
        setSelectedUser(null)
    }

    const handleCloseModal = () => {
        setIsModalVisible(false)
        setSelectedUser(null)
        setCurrentUser(null)
    }

    const togglePaidStatus = async (id) => {
        try {
            setIsLoaded(false)

            await api.patch(`/inscriptions/${id}/paid`)
            message.success(
                'Le statut de paiement a été mis à jour avec succès'
            )
            fetchInscriptions(pagination.current, pagination.pageSize, filters)
        } catch (error) {
            message.error('Erreur lors de la mise à jour du statut de paiement')
            setIsLoaded(true)
        }
    }

    const handleMenuClick = (record, { key }) => {
        switch (key) {
            case 'info':
                handleOpenModal(record)
                break
            case 'edit':
                navigate(`/inscription/${record._id}`)
                break
            case 'delete':
                handleOpenDeleteModal(record)
                break
            default:
                break
        }
    }

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'nom',
            key: 'nom',
            align: 'center',
            ellipsis: true,
            responsive: ['md'], // Only visible on medium and larger screens
        },
        {
            title: 'Prénom',
            dataIndex: 'prenom',
            key: 'prenom',
            align: 'center',
            ellipsis: true,
            responsive: ['xs', 'sm', 'md'], // Only visible on medium and larger screens
        },
        {
            title: 'Mineur',
            dataIndex: 'mineur',
            key: 'mineur',
            align: 'center',
            render: (mineur) => (
                <Tag color={mineur ? 'green' : 'red'}>
                    {mineur ? 'Oui' : 'Non'}
                </Tag>
            ),
            responsive: ['sm', 'md'], // Visible on small and medium screens
        },
        {
            title: 'Payé',
            dataIndex: 'paid',
            key: 'paid',
            align: 'center',
            render: (paid, record) => (
                <>
                    <Tag
                        color={paid ? 'green' : 'red'}
                        onClick={() => togglePaidStatus(record._id)}
                        style={{ cursor: 'pointer' }}
                    >
                        {paid ? 'Oui' : 'Non'}
                    </Tag>
                    {record.warning && (
                        <Tooltip title={record.warning}>
                            <Tag
                                color="red"
                                style={{ marginLeft: 8, cursor: 'pointer' }}
                            >
                                !!
                            </Tag>
                        </Tooltip>
                    )}
                </>
            ),
            responsive: ['xs', 'sm', 'md'], // Visible on small and medium screens
        },
        {
            title: "Date d'inscription",
            dataIndex: 'dateInscription',
            key: 'dateInscription',
            align: 'center',
            sorter: true,
            render: (dateInscription) =>
                moment(dateInscription).format('DD/MM/YYYY'),
            responsive: ['md'],
        },
        {
            title: 'Elo STD',
            dataIndex: 'elo',
            key: 'elo',
            align: 'center',
            sorter: true,
            render: (elo) => elo,
            responsive: ['md'],
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (record) =>
                isMobile ? (
                    <Dropdown
                        overlay={
                            <Menu onClick={(e) => handleMenuClick(record, e)}>
                                <Menu.Item
                                    key="info"
                                    icon={<InfoCircleOutlined />}
                                >
                                    Info
                                </Menu.Item>
                                <Menu.Item key="edit" icon={<EditOutlined />}>
                                    Modifier
                                </Menu.Item>
                                <Menu.Item
                                    key="delete"
                                    icon={<DeleteOutlined />}
                                >
                                    Supprimer
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        <Button icon={<MoreOutlined />} />
                    </Dropdown>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}
                    >
                        <Button
                            type="primary"
                            icon={<InfoCircleOutlined />}
                            onClick={() => handleOpenModal(record)}
                        />
                        <Button
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => {
                                navigate(`/inscription/${record._id}`)
                            }}
                        />
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                handleOpenDeleteModal(record)
                            }}
                        />
                    </div>
                ),
            responsive: ['xs', 'sm', 'md'], // Visible on all screens
        },
    ]

    return (
        <div>
            {!isLoaded && <Loader />}
            <Input
                placeholder="Rechercher par nom ou prénom"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ marginBottom: 16 }}
            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 16,
                }}
            >
                <Button type="primary" onClick={handleCheckWarning}>
                    Synchroniser FFE
                </Button>
                <ExportInscription queryParams={queryParams} />
            </div>
            <div style={{ marginBottom: 16 }}>
                <span style={{ marginRight: 8 }}>Filtrer mineur :</span>
                <Select
                    value={
                        filters.mineur === undefined
                            ? 'Tous'
                            : filters.mineur
                            ? 'Oui'
                            : 'Non'
                    }
                    onChange={handleMineurFilterChange}
                    style={{ width: 120 }}
                >
                    <Option value="Tous">Tous</Option>
                    <Option value="Oui">Oui</Option>
                    <Option value="Non">Non</Option>
                </Select>
                <span style={{ marginLeft: 16, marginRight: 8 }}>
                    Filtrer payé :
                </span>
                <Select
                    value={
                        filters.paid === undefined
                            ? 'Tous'
                            : filters.paid
                            ? 'Oui'
                            : 'Non'
                    }
                    onChange={handlePaidFilterChange}
                    style={{ width: 120 }}
                >
                    <Option value="Tous">Tous</Option>
                    <Option value="Oui">Oui</Option>
                    <Option value="Non">Non</Option>
                </Select>
                <span style={{ marginLeft: 16, marginRight: 8 }}>
                    Filtrer par licence :
                </span>
                <Select
                    value={filters.licenseType}
                    onChange={handleLicenseFilterChange}
                    style={{ width: 120 }}
                >
                    <Option value="Tous">Tous</Option>
                    <Option value="A">Licence A</Option>
                    <Option value="B">Licence B</Option>
                    {/*<Option value="C">Cours du mercredi</Option>*/}
                    <Option value="N">Non licencié</Option>
                </Select>
                <span style={{ marginLeft: 16, marginRight: 8 }}>
                    Filtrer par avertissement
                </span>
                <Switch
                    value={filters.warning}
                    checked={filters.warning}
                    onChange={handleWarningFilterChange}
                />
                <Slider
                    range
                    step={50}
                    min={0}
                    max={3000}
                    defaultValue={[0, 3000]}
                    onChange={(value) => debouncedHandleChangeEloRange(value)}
                />
            </div>

            <Table
                columns={columns}
                dataSource={inscriptions}
                rowKey="_id"
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    position: ['topRight', 'bottomRight'], // Display pagination at both top and bottom
                }}
                onChange={handleTableChange}
                responsive
            />
            <Button
                style={{ width: '100%' }}
                type="primary"
                onClick={handleImportMembers}
            >
                Importer les membres depuis la FFE
            </Button>
            <Modal
                title={`Supprimer l'utilisateur ${selectedUser?.prenom} ${selectedUser?.nom}`}
                open={isDeleteModalVisible}
                onCancel={handleCloseDeleteModal}
                onOk={async () => {
                    setIsLoaded(false)
                    await api.delete(`/inscriptions/${selectedUser._id}`)
                    message.success('Utilisateur supprimé avec succès')
                    await fetchInscriptions(
                        pagination.current,
                        pagination.pageSize,
                        filters,
                        sortOrder
                    )
                    setIsDeleteModalVisible(false)
                    setIsLoaded(true)
                }}
                okButtonProps={{ danger: true }} // Make the "Ok" button red
                okText="Supprimer" // Customize the button text if needed
                cancelText="Annuler" // Customize the button text if needed
            >
                <p>
                    Êtes-vous sûr de vouloir supprimer l'utilisateur{' '}
                    {selectedUser?.prenom} {selectedUser?.nom} ?
                </p>
            </Modal>

            <Modal
                title={`Info de l'utilisateur ${selectedUser?.prenom} ${selectedUser?.nom}`}
                open={isModalVisible}
                onCancel={handleCloseModal}
                footer={[
                    <Button key="close" onClick={handleCloseModal}>
                        Fermer
                    </Button>,
                ]}
            >
                {currentUser ? (
                    <>
                        <div>
                            <FullInfoCard data={currentUser} />
                        </div>
                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </Modal>
        </div>
    )
}

export default AdminDashboard
