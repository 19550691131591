import React from 'react'
import { Card, Button, Row, Col, message } from 'antd'
import { CopyOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'
import { bufferToImgUrl } from '../helpers/buffers'

// Utility to copy to clipboard
const copyToClipboard = (label, value) => {
    navigator.clipboard.writeText(value).then(
        () => {
            message.success(label + ' copié dans le presse-papier!')
        },
        (err) => {
            console.error('Could not copy text: ', err)
        }
    )
}

// Function to render a single info row
const renderInfoRow = ({
    label,
    value,
    isPhone = false,
    isEmail = false,
    copy = false,
}) => {
    if (value === 'N/A') return null

    const buttons = []

    if (copy) {
        buttons.push(
            <Button
                icon={<CopyOutlined />}
                onClick={() => copyToClipboard(label, value)}
                shape="circle"
                key="copy"
            />
        )
    }

    if (isPhone && isMobile) {
        buttons.push(
            <Button
                icon={<PhoneOutlined />}
                href={`tel:${value}`}
                shape="circle"
                style={{ marginLeft: '10px' }}
                key="call"
            />
        )
    }

    if (isEmail) {
        buttons.push(
            <Button
                icon={<MailOutlined />}
                href={`mailto:${value}`}
                shape="circle"
                style={{ marginLeft: '10px' }}
                key="email"
            />
        )
    }

    return (
        <Row
            gutter={[16, 16]}
            style={{ marginBottom: '10px', alignItems: 'center' }}
        >
            <Col span={8}>
                <strong>{label}:</strong>
            </Col>
            <Col span={16} style={{ display: 'flex', alignItems: 'center' }}>
                <span>{value}</span>
                <div style={{ marginLeft: 'auto' }}>{buttons}</div>
            </Col>
        </Row>
    )
}

// Main component to display all data in a card
const FullInfoCard = ({ data }) => {
    const {
        nom = 'N/A',
        prenom = 'N/A',
        elo = 0,
        dateDeNaissance = 'N/A',
        mineur = false,
        nationality = 'N/A',
        mail = 'N/A',
        portable = 'N/A',
        photoAuthorization = 'N/A',
        licenseType = 'N/A',
        autorisationDepartSeul = 'N/A',
        signature = false,
        dateInscription = 'N/A',
        paid = 'N/A',
        warning = 'N/A',
        emergencyContact = 'N/A',
        parentInfo = {
            pere: {
                nom: 'N/A',
                prenom: 'N/A',
                telephone: 'N/A',
            },
            mere: {
                nom: 'N/A',
                prenom: 'N/A',
                telephone: 'N/A',
            },
        },
    } = data

    const license = licenseType === 'C' ? 'Cours (B)' : licenseType

    return (
        <Card bordered={true} style={{ width: '100%' }}>
            {/* Main Personal Info */}
            {renderInfoRow({ label: 'Nom', value: nom, copy: true })}
            {renderInfoRow({ label: 'Prénom', value: prenom, copy: true })}
            {renderInfoRow({ label: 'Elo', value: elo })}
            {renderInfoRow({
                label: 'Date de Naissance',
                value: new Date(dateDeNaissance).toLocaleDateString(),
                copy: true,
            })}
            {renderInfoRow({ label: 'Mineur', value: mineur ? 'Oui' : 'Non' })}
            {renderInfoRow({ label: 'Nationalité', value: nationality })}
            {renderInfoRow({
                label: 'Email',
                value: mail,
                isEmail: true,
                copy: true,
            })}
            {renderInfoRow({
                label: 'Portable',
                value: portable,
                isPhone: true,
                copy: true,
            })}
            {renderInfoRow({
                label: 'Autorisation photo',
                value: photoAuthorization ? 'Oui' : 'Non',
            })}
            {renderInfoRow({ label: 'Type de licence', value: license })}
            {mineur &&
                renderInfoRow({
                    label: 'Autorisation départ seul',
                    value: autorisationDepartSeul ? 'Oui' : 'Non',
                })}
            {renderInfoRow({
                label: "Date d'inscription",
                value: new Date(dateInscription).toLocaleDateString(),
            })}
            {renderInfoRow({ label: 'A payé', value: paid ? 'Oui' : 'Non' })}
            {renderInfoRow({ label: 'Avertissement', value: warning })}

            {/* Emergency Contact */}
            <h3>Contact d'urgence</h3>
            {renderInfoRow({
                label: 'Nom',
                value: emergencyContact.nom,
            })}
            {renderInfoRow({
                label: 'Prénom',
                value: emergencyContact.prenom,
            })}
            {renderInfoRow({
                label: 'Téléphone',
                value: emergencyContact.telephone,
                isPhone: true,
                copy: true,
            })}

            {mineur && (
                <>
                    <h3>Informations des parents</h3>
                    <h4>Père</h4>
                    {renderInfoRow({
                        label: 'Nom',
                        value: parentInfo?.pere.nom,
                    })}
                    {renderInfoRow({
                        label: 'Prénom',
                        value: parentInfo?.pere.prenom,
                    })}
                    {renderInfoRow({
                        label: 'Téléphone',
                        value: parentInfo?.pere.telephone,
                        isPhone: true,
                        copy: true,
                    })}
                    <h4>Mère</h4>
                    {renderInfoRow({
                        label: 'Nom',
                        value: parentInfo?.mere.nom,
                    })}
                    {renderInfoRow({
                        label: 'Prénom',
                        value: parentInfo?.mere.prenom,
                    })}
                    {renderInfoRow({
                        label: 'Téléphone',
                        value: parentInfo?.mere.telephone,
                        isPhone: true,
                        copy: true,
                    })}
                </>
            )}

            {signature &&
                renderInfoRow({
                    label: 'Signature',
                    value: (
                        <img
                            src={bufferToImgUrl(signature)}
                            alt="Signature"
                            style={{ maxWidth: '100%' }}
                        />
                    ),
                })}
        </Card>
    )
}

export default FullInfoCard
