export const calculateAge = (dateString) => {
    const birthDate = new Date(dateString) // Date string in 'YYYY-MM-DD' format
    const currentDate = new Date()

    let age = currentDate.getFullYear() - birthDate.getFullYear()
    const monthDifference = currentDate.getMonth() - birthDate.getMonth()

    if (
        monthDifference < 0 ||
        (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
        age-- // If the birthdate hasn't occurred yet this year, subtract 1
    }

    return age
}
