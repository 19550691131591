import axios from 'axios'

// Créer une instance d'Axios
const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080',
})

// Ajouter un intercepteur de requête pour inclure le token
api.interceptors.request.use(
    (config) => {
        // Récupérer le token depuis le localStorage
        const token = localStorage.getItem('club-token')

        if (token) {
            // Si un token est présent, l'ajouter aux en-têtes de la requête
            config.headers['Authorization'] = `Bearer ${token}`
        }

        return config
    },
    (error) => {
        // Gérer les erreurs avant qu'elles ne soient envoyées
        return Promise.reject(error)
    }
)

// Optionnel : Intercepteur de réponse pour gérer les erreurs d'authentification
api.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Si le token est expiré ou non valide, rediriger ou afficher un message d'erreur
            console.error('Token invalide ou expiré. Redirection...')
            // Option : redirection vers la page de connexion ou traitement
            // window.location.href = '/login';
        }

        return Promise.reject(error)
    }
)

export default api
