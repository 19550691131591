import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'antd'
import { FormOutlined, QrcodeOutlined, TeamOutlined } from '@ant-design/icons'
import CustomCard from '../components/CustomCard'

const HomePage = () => {
    const navigate = useNavigate()

    return (
        <div
            style={{
                padding: '20px',
            }}
        >
            <Row gutter={[16, 16]} justify="center" style={{ width: '100%' }}>
                <Col xs={24} md={12} style={{ maxWidth: '300px' }}>
                    <CustomCard
                        title="Inscription"
                        description="Accéder au formulaire d'inscription"
                        icon={<FormOutlined />}
                        onClick={() => navigate('/inscription')}
                    />
                </Col>
                <Col xs={24} md={12} style={{ maxWidth: '300px' }}>
                    <CustomCard
                        title="Membres"
                        description="Accédez à la liste des membres"
                        icon={<TeamOutlined />}
                        onClick={() => navigate('/dashboard')}
                    />
                </Col>
                <Col xs={24} md={12} style={{ maxWidth: '300px' }}>
                    <CustomCard
                        title="Inscription publique"
                        description="Partagez un lien d'inscription à usage unique"
                        icon={<QrcodeOutlined />}
                        onClick={() => navigate('/generate-inscription-link')}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default HomePage
