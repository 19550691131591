import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import api from '../request'
import InscriptionPage from './InscriptionPage'
import Loader from '../components/Loader'
import ErrorMessage from '../components/ErrorMessage'

const ShareInscription = () => {
    const [requestParams] = useSearchParams()
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const token = requestParams.get('token')

    useEffect(() => {
        if (!token) {
            setIsLoading(false)
            setError('No token found in URL')
            return
        }
        api.get('/checkInscriptionToken', {
            params: {
                token,
            },
        })
            .then(() => {
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                setError(error)
            })
    }, [token])

    if (isLoading) {
        return <Loader />
    }

    if (error) {
        return <ErrorMessage message="Le lien n'est pas valide ou a expiré." />
    }

    return <InscriptionPage />
}

export default ShareInscription
