import React from 'react'
import { Row, Col, Typography } from 'antd'

const { Title, Text } = Typography

const ErrorMessage = ({ message }) => {
    return (
        <Row
            justify="center"
            align="middle"
            style={{
                minHeight: '20vh',
                textAlign: 'center',
                padding: '0 20px',
            }}
        >
            <Col>
                <Title
                    level={2}
                    style={{ color: '#ff4d4f', userSelect: 'none' }}
                >
                    Oops! Bah ça alors...
                </Title>
                <Text
                    type="danger"
                    style={{ fontSize: '1.2rem', userSelect: 'none' }}
                >
                    {message ||
                        'An unexpected error occurred. Please try again later.'}
                </Text>
            </Col>
        </Row>
    )
}

export default ErrorMessage
